/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/core'
import React from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'

import Button from './Button'
import Link from './Link'
import Icon from './Icon'
import Text from './Text'

function Actions({ actions }) {
  const styles = {
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
    item: {
      marginLeft: 10,
      display: 'inline-block',
    },
  }

  return (
    <ul css={styles.list}>
      {actions.map((action, key) => (
        <li key={key} css={styles.item}>
          <Button {...action} ghost />
        </li>
      ))}
    </ul>
  )
}

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
}

function TopLine({ title, url, actions }) {
  const styles = {
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      minHeight: 40,
    },
    title: {
      textTransform: 'uppercase',
    },
  }

  return (
    <div css={styles.wrapper}>
      <Link href={url}>
        <Text.h3 css={styles.title}>{title}</Text.h3>
      </Link>
      {actions && actions.length > 0 && <Actions actions={actions} />}
    </div>
  )
}

TopLine.propTypes = {
  title: PropTypes.any,
  url: PropTypes.string,
  actions: Actions.propTypes.actions,
}

function Items({ items }) {
  const styles = {
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
    },
    item: {
      flex: 1,
      '&:not(:last-of-type)': { marginRight: 30 },
    },
    label: {
      display: 'block',
      marginBottom: 5,
      minHeight: 45,
    },
    value: {
      fontWeight: 'normal',
    },
  }

  return (
    <ul css={styles.list}>
      {items.map((item, key) => (
        <li key={key} css={styles.item}>
          <Text data-cy-info={item.label} css={styles.label} size="smallText">
            {item.label}
          </Text>
          <Text size="typo4" css={styles.value}>
            {item.value}
          </Text>
        </li>
      ))}
    </ul>
  )
}

Items.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
}

export default function ContractItem({
  title,
  link,
  actions,
  items,
  color,
  icon,
  style, // react-spring requirement
}) {
  const theme = React.useContext(ThemeContext)

  const styles = {
    wrapper: {
      position: 'relative',
      background: theme.colors.white,
      border: `1px solid ${theme.colors.lightGray}`,
      padding: '10px 10px 20px 80px',
      '&:not(:last-of-type)': { marginBottom: 20 },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: -1,
        bottom: -1,
        width: 6,
        background: theme.colors[color],
      },
    },
    icon: {
      position: 'absolute',
      top: 15,
      left: 25,
    },
  }

  return (
    <animated.div style={style} css={styles.wrapper} data-cy="ContractBlock">
      <Icon name={icon} color={color} css={styles.icon} size={40} />
      <TopLine title={title} url={link} actions={actions} />
      {Array.isArray(items) && <Items items={items.slice(0, 6)} />}
    </animated.div>
  )
}

ContractItem.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,

  title: TopLine.propTypes.title,
  link: TopLine.propTypes.url,
  actions: TopLine.propTypes.actions,

  items: Items.propTypes.items,
}

export const ContractItemSkeleton = () => {
  const theme = React.useContext(ThemeContext)

  const styles = {
    item: {
      ...theme.skeletonWave,
      position: 'relative',
      height: 170,
      '&:not(:last-of-type)': { marginBottom: 20 },
    },
  }

  return <div css={styles.item} />
}
