import React from 'react'

const SvgDocx = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path d="M44 27H4l-3-4 4-4h38l4 4z" fill="#12376b" />
    <path
      d="M41 47H7a2 2 0 01-2-2V3a2 2 0 012-2h24l12 12v32a2 2 0 01-2 2z"
      fill="#e6e6e6"
    />
    <path d="M31 1v10a2 2 0 002 2h10z" fill="#b3b3b3" />
    <path d="M45 41H3a2 2 0 01-2-2V23h46v16a2 2 0 01-2 2z" fill="#27549b" />
    <g fill="#fff">
      <path d="M15.549 31.907c0 1.311-.361 2.32-1.083 3.029-.722.71-1.736 1.064-3.042 1.064H8.883v-7.853h2.718c1.261 0 2.233.322 2.919.967s1.029 1.575 1.029 2.793zm-2.203.075c0-.72-.142-1.253-.427-1.601-.284-.348-.717-.521-1.297-.521h-.618v4.398h.473c.645 0 1.117-.188 1.418-.562s.451-.944.451-1.714zM24.303 32.062c0 1.322-.324 2.326-.972 3.014s-1.597 1.031-2.847 1.031c-1.232 0-2.176-.346-2.834-1.037-.656-.69-.984-1.697-.984-3.018 0-1.308.326-2.306.98-2.995.652-.689 1.603-1.034 2.849-1.034 1.249 0 2.196.343 2.841 1.026s.967 1.689.967 3.013zm-5.403 0c0 1.519.527 2.277 1.584 2.277.537 0 .936-.184 1.195-.553s.389-.943.389-1.725c0-.783-.131-1.363-.395-1.737s-.656-.562-1.179-.562c-1.062.002-1.594.768-1.594 2.3zM29.218 29.775c-.505 0-.899.206-1.182.62-.282.413-.425.983-.425 1.71 0 1.512.575 2.268 1.725 2.268.348 0 .684-.049 1.01-.146s.653-.213.982-.349v1.793c-.654.291-1.396.436-2.223.436-1.186 0-2.094-.344-2.727-1.031-.632-.688-.947-1.682-.947-2.98 0-.813.152-1.527.459-2.144s.746-1.09 1.32-1.421c.576-.331 1.252-.496 2.028-.496.849 0 1.659.184 2.433.553l-.649 1.67c-.29-.136-.58-.25-.87-.344a3.04 3.04 0 00-.934-.139zM39.934 36h-2.455l-1.531-2.455L34.434 36h-2.401l2.604-4.018-2.449-3.835h2.354l1.418 2.428 1.363-2.428h2.423l-2.487 4.001z" />
    </g>
  </svg>
)

export default SvgDocx
