import React from 'react'

const SvgSpinner = props => (
  <svg
    id="spinner_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 115.3 118.7"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.spinner_svg__st0{fill:#119da4}'}</style>
    <path
      className="spinner_svg__st0"
      d="M57.7 7.4c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6V17c0-5.3 2.6-9.6 6-9.6z"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.9166666666666666s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M61.3 2.3c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(30 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.8333333333333334s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M61.9-3.9c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6V5.7c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(60 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.75s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M59.4-9.7c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6V-.1c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(90 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.6666666666666666s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M54.3-13.3c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(120 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.5833333333333334s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M48.1-13.9c3.3 0 6 4.3 6 9.6V.5c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.6-9.6 6-9.6z"
      transform="rotate(150 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.5s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M42.3-11.4c3.3 0 6 4.3 6 9.6V3c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(180 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.4166666666666667s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M38.7-6.3c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6V3.3c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(210 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.3333333333333333s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M38.1-.1c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6V9.5c0-5.3 2.6-9.6 6-9.6z"
      transform="rotate(240 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.25s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M40.6 5.7c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.4 2.7-9.6 6-9.6z"
      transform="rotate(270 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.16666666666666666s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M45.7 9.3c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(300 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="-0.08333333333333333s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
    <path
      className="spinner_svg__st0"
      d="M51.9 9.9c3.3 0 6 4.3 6 9.6v4.8c0 5.3-2.7 9.6-6 9.6s-6-4.3-6-9.6v-4.8c0-5.3 2.7-9.6 6-9.6z"
      transform="rotate(330 50 50)"
    >
      <animate
        accumulate="none"
        additive="replace"
        attributeName="opacity"
        begin="0s"
        calcMode="linear"
        dur="1s"
        fill="remove"
        keyTimes="0;1"
        repeatCount="indefinite"
        restart="always"
        values="1;0"
      />
    </path>
  </svg>
)

export default SvgSpinner
