import React from 'react'

const SvgPptx = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path fill="#AA3111" d="M44 27H4l-3-4 4-4h38l4 4z" />
    <path
      fill="#E6E6E6"
      d="M41 47H7a2 2 0 01-2-2V3a2 2 0 012-2h24l12 12v32a2 2 0 01-2 2z"
    />
    <path fill="#B3B3B3" d="M31 1v10a2 2 0 002 2h10L31 1z" />
    <path fill="#D44519" d="M45 41H3a2 2 0 01-2-2V23h46v16a2 2 0 01-2 2z" />
    <path
      fill="#FFF"
      d="M16.371 30.645c0 .878-.259 1.556-.776 2.033-.518.479-1.252.717-2.205.717h-.596V36h-2.122v-7.853h2.718c.992 0 1.737.217 2.234.649.498.434.747 1.051.747 1.849zm-3.578 1.021h.387c.319 0 .572-.09.76-.27.188-.179.282-.426.282-.74 0-.53-.293-.795-.881-.795h-.548v1.805zM23.321 30.645c0 .878-.259 1.556-.776 2.033-.518.479-1.252.717-2.205.717h-.596V36h-2.122v-7.853h2.718c.992 0 1.737.217 2.234.649.498.434.747 1.051.747 1.849zm-3.577 1.021h.387c.319 0 .572-.09.76-.27.188-.179.282-.426.282-.74 0-.53-.293-.795-.881-.795h-.548v1.805zM28.037 36h-2.122v-6.117h-1.917v-1.735h5.951v1.735h-1.912V36zM38.145 36H35.69l-1.531-2.455L32.645 36h-2.401l2.605-4.018-2.449-3.835h2.353l1.418 2.428 1.364-2.428h2.422l-2.487 4.001L38.145 36z"
    />
  </svg>
)

export default SvgPptx
