import React from 'react'

const SvgGreycloud = props => (
  <svg viewBox="0 0 75 35" {...props}>
    <g fill="#D8D8D8" fillRule="evenodd">
      <path d="M45.764 10.273c0 5.674-4.61 10.274-10.299 10.274-5.688 0-10.298-4.6-10.298-10.274C25.167 4.6 29.777 0 35.465 0c5.689 0 10.3 4.6 10.3 10.273" />
      <path d="M55.77 21.182c0 6.673-5.424 12.084-12.113 12.084-6.691 0-12.115-5.41-12.115-12.084 0-6.673 5.424-12.083 12.115-12.083 6.69 0 12.113 5.41 12.113 12.083" />
      <path d="M60.047 26.813c0 4.163-3.384 7.539-7.558 7.539-4.174 0-7.558-3.376-7.558-7.539s3.384-7.539 7.558-7.539c4.174 0 7.558 3.376 7.558 7.54" />
      <path d="M66.375 29.663a4.695 4.695 0 01-4.7 4.689c-2.596 0-4.7-2.1-4.7-4.689a4.695 4.695 0 014.7-4.688c2.595 0 4.7 2.1 4.7 4.688" />
      <path d="M71.495 30.58a3.777 3.777 0 01-3.782 3.772 3.776 3.776 0 01-3.781-3.772 3.776 3.776 0 013.781-3.772 3.777 3.777 0 013.782 3.772" />
      <path d="M74.448 31.798a2.556 2.556 0 01-2.56 2.554 2.557 2.557 0 01-2.56-2.554 2.557 2.557 0 012.56-2.554 2.556 2.556 0 012.56 2.554M9.401 29.663a4.695 4.695 0 01-4.7 4.689A4.695 4.695 0 010 29.663a4.695 4.695 0 014.701-4.688c2.595 0 4.7 2.1 4.7 4.688" />
      <path d="M21.313 26.813c0 4.163-3.384 7.539-7.557 7.539-4.174 0-7.558-3.376-7.558-7.539s3.384-7.539 7.558-7.539c4.173 0 7.557 3.376 7.557 7.54" />
      <path d="M36.643 22.467c0 5.261-4.277 9.527-9.551 9.527-5.276 0-9.551-4.266-9.551-9.527 0-5.262 4.275-9.528 9.55-9.528s9.552 4.266 9.552 9.528" />
      <path d="M4.701 34.352h66.794l-4.726-3.962-21.267-4.583-16.147-2.488-11.42 3.405z" />
    </g>
  </svg>
)

export default SvgGreycloud
