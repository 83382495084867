import React from 'react'

const SvgPicture = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="picture_svg__a"
        d="M23.478 0c.313 0 .522.209.522.522v22.956c0 .313-.209.522-.522.522H.522C.209 24 0 23.791 0 23.478V.522C0 .209.209 0 .522 0h22.956zm-.521 22.957V1.043H1.043v21.914h21.914zm-19.305-3.13a.564.564 0 01-.365-.157c-.209-.157-.26-.522-.052-.73l5.217-6.262a.495.495 0 01.678-.104l4.853 2.922 6.052-5.948a.504.504 0 01.73 0 .504.504 0 010 .73l-6.26 6.261c-.157.209-.418.209-.627.104l-4.852-2.921-4.956 5.895a.475.475 0 01-.418.21zM9.913 9.39a2.583 2.583 0 01-2.609-2.608 2.583 2.583 0 012.609-2.61 2.583 2.583 0 012.609 2.61A2.583 2.583 0 019.913 9.39zm0-4.174c-.887 0-1.565.679-1.565 1.566s.678 1.565 1.565 1.565c.887 0 1.565-.678 1.565-1.565 0-.887-.678-1.566-1.565-1.566z"
      />
    </defs>
    <use xlinkHref="#picture_svg__a" />
  </svg>
)

export default SvgPicture
