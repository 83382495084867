/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useContext } from 'react'
import { jsx, ThemeContext } from '@emotion/core'
import PropTypes from 'prop-types'
import { TwitterPicker } from 'react-color'
import { Text, PieChart, Button } from '../'

// prettier-ignore
const THEME_0 = ['#0296f0', '#85d1ff', '#3c708f', '#119da4', '#00d9d8', '#6760e2', '#b8b5ec']
// prettier-ignore
const THEME_1 = ['#ff9597', '#a82028', '#ff1a00', '#ff8600', '#e6c975', '#c06f0c', '#df9601']
// prettier-ignore
const THEME_2 = ['#5bc408', '#71ff00', '#41711b', '#62b26c', '#308d7b', '#1be9c2', '#bedfcd']

//
// SELECT MAIN COLORS
//
const ColorPicker = ({ title, desc, color, onChangeColor }) => {
  const {
    colors: { label: labelColor },
  } = useContext(ThemeContext)
  const [pickerIsOpen, togglePicker] = useState(false)

  const styles = {
    colorBoxWrapper: {
      position: 'relative',
      display: 'flex',
      width: 130,
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 15,
    },
    colorBox: {
      width: '100%',
      height: 30,
      backgroundColor: color,
      borderRadius: 4,
      cursor: 'pointer',
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      marginRight: 20,
    },
    description: {
      color: labelColor,
    },
  }

  return (
    <div css={{ marginBottom: 15 }}>
      <Text tag="span" css={styles.title}>
        {title}
      </Text>
      <Text tag="span" mb={15} css={styles.description}>
        {desc}
      </Text>
      <div css={styles.colorBoxWrapper}>
        <div
          css={styles.colorBox}
          onClick={() => togglePicker(!pickerIsOpen)}
        />
        {pickerIsOpen && (
          <div css={{ position: 'absolute', zIndex: 1, top: 35, left: 60 }}>
            <TwitterPicker
              onChangeComplete={({ hex }) => {
                onChangeColor(hex)
                togglePicker(!pickerIsOpen)
              }}
            />
          </div>
        )}
        <div css={{ alignSelf: 'center' }}>
          <Text css={styles.description}>{color.toUpperCase()}</Text>
        </div>
      </div>
    </div>
  )
}

const MainColors = ({
  wording,
  primaryColor,
  secondaryColor,
  onChangePrimaryColor,
  onChangeSecondaryColor,
}) => (
  <div css={{ marginBottom: 40 }}>
    <Text tag="h2" mb={15}>
      {wording.title}
    </Text>
    <ColorPicker
      title={wording.first_color}
      desc={wording.first_color_desc}
      color={primaryColor}
      onChangeColor={onChangePrimaryColor}
    />
    <ColorPicker
      title={wording.second_color}
      desc={wording.second_color_desc}
      color={secondaryColor}
      onChangeColor={onChangeSecondaryColor}
    />
  </div>
)

//
// SELECT CHART COLORS
//
const ColorsList = ({ colors }) => {
  const styles = {
    colorBoxWrapper: { display: 'flex', flex: 1 },
    colorBox: {
      flex: 1,
      height: 30,
      borderRadius: 4,
      marginRight: 5,
    },
  }
  return (
    <div css={styles.colorBoxWrapper}>
      {colors.map((color, index) => (
        <div key={index} css={{ ...styles.colorBox, backgroundColor: color }} />
      ))}
    </div>
  )
}

const ChartColors = ({ wording, selectedTheme, onSelectChartTheme }) => {
  const {
    colors: { label: labelColor },
  } = useContext(ThemeContext)

  const PieSelector = ({ index, theme }) => {
    const pieValues = [
      { color: theme[0], value: 10 },
      { color: theme[1], value: 15 },
      { color: theme[2], value: 4 },
      { color: theme[3], value: 20 },
      { color: theme[4], value: 8 },
      { color: theme[5], value: 11 },
      { color: theme[6], value: 14 },
    ]

    return (
      <div css={{ flex: 1, marginRight: 40, maxWidth: 280 }}>
        <div css={{ marginBottom: 15 }}>
          <Button
            label={`${wording.theme} ${index + 1}`}
            ghost={selectedTheme !== index}
            onClick={() => onSelectChartTheme(index)}
          />
        </div>
        <ColorsList colors={theme} />
        <div css={{ width: '100%' }}>
          <PieChart values={pieValues} isAnimationActive={false} />
        </div>
      </div>
    )
  }

  return (
    <>
      <Text tag="h2">{wording.chart_colors_title}</Text>
      <Text tag="p" mb={15} css={{ color: labelColor }}>
        {wording.chart_colors_desc}
      </Text>
      <div css={{ display: 'flex', flexWrap: 'wrap' }}>
        <PieSelector index={0} theme={THEME_0} />
        <PieSelector index={1} theme={THEME_1} />
        <PieSelector index={2} theme={THEME_2} />
      </div>
    </>
  )
}

const ThemeSelectors = ({
  wording,
  primaryColor,
  secondaryColor,
  selectedTheme,
  onChange,
}) => {
  const [primaryColorState, setPrimaryColor] = useState(primaryColor)
  const [secondaryColorState, setSecondaryColor] = useState(secondaryColor)
  const [selectedChartThemeState, setChartTheme] = useState(selectedTheme)

  useEffect(() => {
    onChange({
      primaryColor: primaryColorState,
      secondaryColor: secondaryColorState,
      selectedChartTheme: selectedChartThemeState,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColorState, secondaryColorState, selectedChartThemeState])

  return (
    <div>
      <MainColors
        wording={wording}
        primaryColor={primaryColorState}
        secondaryColor={secondaryColorState}
        onChangePrimaryColor={setPrimaryColor}
        onChangeSecondaryColor={setSecondaryColor}
      />
      <ChartColors
        wording={wording}
        selectedTheme={selectedChartThemeState}
        onSelectChartTheme={setChartTheme}
      />
    </div>
  )
}

export default ThemeSelectors

ThemeSelectors.defaultProps = {
  onChange: null,
}

ThemeSelectors.propTypes = {
  onChange: PropTypes.func,
  wording: PropTypes.shape({
    title: PropTypes.string.isRequired,
    first_color: PropTypes.string.isRequired,
    first_color_desc: PropTypes.string.isRequired,
    second_color: PropTypes.string.isRequired,
    second_color_desc: PropTypes.string.isRequired,
    chart_colors_title: PropTypes.string.isRequired,
    chart_colors_desc: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
  }),
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  selectedTheme: PropTypes.number.isRequired,
}
