import React from 'react'

const SvgUsers = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="users_svg__a"
        d="M9.565 12.174a2.612 2.612 0 012.609-2.609 2.612 2.612 0 012.609 2.609 2.612 2.612 0 01-2.61 2.609 2.612 2.612 0 01-2.608-2.61zm4.174 0c0-.863-.702-1.565-1.565-1.565s-1.565.702-1.565 1.565.702 1.565 1.565 1.565 1.565-.702 1.565-1.565zm11.478 0a2.612 2.612 0 012.61-2.609 2.612 2.612 0 012.608 2.609 2.612 2.612 0 01-2.609 2.609 2.612 2.612 0 01-2.609-2.61zm4.174 0c0-.863-.702-1.565-1.565-1.565s-1.565.702-1.565 1.565.702 1.565 1.565 1.565 1.565-.702 1.565-1.565zm0 4.174A2.612 2.612 0 0132 18.957v4.695a.52.52 0 01-.357.495l-1.242.414-.49 5.399a.522.522 0 01-.52.475h-3.13a.522.522 0 010-1.044h2.654l.478-5.265a.521.521 0 01.355-.447l1.209-.403v-4.32c0-.862-.703-1.565-1.566-1.565h-3.13c-.425 0-.817.17-1.106.462.36.447.584 1.008.584 1.625v5.218c0 .24-.163.448-.395.506l-1.725.43-.49 5.89a.522.522 0 01-.52.478H17.39a.522.522 0 01-.52-.478l-.49-5.89-1.725-.43a.521.521 0 01-.395-.506v-5.218c0-.617.225-1.178.584-1.625a1.549 1.549 0 00-1.106-.462h-3.13c-.863 0-1.566.703-1.566 1.566v4.319l1.209.403a.521.521 0 01.355.448l.478 5.264h2.654a.522.522 0 010 1.044h-3.13a.522.522 0 01-.52-.474l-.49-5.4-1.242-.414A.522.522 0 018 23.652v-4.695a2.612 2.612 0 012.609-2.61h3.13c.728 0 1.42.315 1.91.84a2.575 2.575 0 011.22-.317h6.261c.444 0 .856.121 1.222.317.49-.525 1.18-.84 1.909-.84h3.13zm-4.695 7.94v-4.81c0-.863-.703-1.565-1.566-1.565h-6.26c-.863 0-1.566.702-1.566 1.565v4.81l1.692.423c.217.055.375.24.394.463l.481 5.783h4.258l.481-5.783a.522.522 0 01.394-.463l1.692-.423zm-8.348-12.636A3.656 3.656 0 0120 8a3.656 3.656 0 013.652 3.652A3.656 3.656 0 0120 15.304a3.656 3.656 0 01-3.652-3.652zm6.26 0A2.612 2.612 0 0020 9.043a2.612 2.612 0 00-2.609 2.61A2.612 2.612 0 0020 14.26a2.612 2.612 0 002.609-2.609z"
      />
    </defs>
    <use transform="translate(-8 -8)" xlinkHref="#users_svg__a" />
  </svg>
)

export default SvgUsers
