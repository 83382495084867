import React from 'react'

const SvgAvatarchildf = props => (
  <svg viewBox="0 0 35 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M5.957 34.894v-23.83C5.957 4.954 10.911 0 17.021 0s11.064 4.953 11.064 11.064v23.83H5.957z"
        fill="#444"
      />
      <path fill="#E5CEBE" d="M11.915 19.574h10.213V32.34H11.915z" />
      <path
        d="M31.22 32.631l-9.092-4.546a8.545 8.545 0 01-5.107 1.702 8.465 8.465 0 01-5.09-1.702l-9.108 4.546A5.106 5.106 0 000 37.198v1.946c0 .473.382.856.859.856h32.325a.854.854 0 00.859-.856v-1.946a5.106 5.106 0 00-2.823-4.567z"
        fill="#E86C60"
      />
      <path
        d="M7.66 13.617v1.702c0 5.162 4.2 9.362 9.361 9.362 5.162 0 9.362-4.2 9.362-9.362v-1.702a2.553 2.553 0 00-2.553-2.553 7.659 7.659 0 01-6.809-4.147 7.659 7.659 0 01-6.808 4.147 2.553 2.553 0 00-2.553 2.553z"
        fill="#EFDACB"
      />
      <path
        d="M9.818 29.14l1.657 3.827c.218.533.89.7 1.332.332l4.214-3.512a8.465 8.465 0 01-5.09-1.702L9.817 29.14zM22.128 28.085a8.545 8.545 0 01-5.107 1.702l4.214 3.512a.851.851 0 001.333-.332l1.659-3.832-2.1-1.05z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgAvatarchildf
