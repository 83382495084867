import React from 'react'

const SvgInstacolor = props => (
  <svg viewBox="0 0 40 40" {...props}>
    <g transform="translate(8 8)" fillRule="nonzero" fill="none">
      <path
        d="M12 0C8.741 0 8.332.014 7.053.072 5.103.161 3.39.639 2.014 2.014.639 3.389.161 5.101.072 7.053.014 8.332 0 8.74 0 12c0 3.259.014 3.668.072 4.948.089 1.95.567 3.662 1.942 5.038 1.375 1.375 3.087 1.853 5.039 1.942C8.332 23.986 8.74 24 12 24c3.259 0 3.668-.014 4.948-.072 1.949-.089 3.662-.567 5.038-1.942 1.375-1.375 1.853-3.088 1.942-5.038.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.947-.089-1.95-.567-3.663-1.942-5.039C20.611.639 18.898.161 16.948.072 15.668.014 15.259 0 12 0z"
        fill="#E02D69"
      />
      <path
        d="M12 5.441c2.136 0 2.389.008 3.232.047.911.041 1.756.224 2.406.874.65.65.832 1.495.874 2.405.038.844.046 1.097.046 3.233s-.008 2.389-.046 3.232c-.041.911-.224 1.756-.874 2.406-.65.65-1.495.832-2.406.874-.843.038-1.096.046-3.232.046s-2.389-.008-3.232-.046c-.911-.041-1.756-.224-2.406-.874-.65-.65-.833-1.495-.874-2.406-.039-.843-.047-1.096-.047-3.232s.008-2.389.047-3.232c.041-.911.224-1.756.874-2.406.65-.65 1.495-.833 2.405-.874.844-.039 1.097-.047 3.233-.047zM12 4c-2.172 0-2.445.009-3.299.048-1.3.06-2.442.378-3.359 1.295C4.426 6.259 4.109 7.4 4.049 8.7 4.009 9.555 4 9.829 4 12c0 2.172.009 2.445.048 3.299.06 1.3.378 2.442 1.295 3.358.916.917 2.058 1.235 3.358 1.295.854.039 1.127.048 3.299.048 2.172 0 2.445-.009 3.299-.048 1.3-.06 2.442-.378 3.358-1.295.917-.916 1.235-2.058 1.295-3.358.039-.854.048-1.127.048-3.299 0-2.172-.009-2.445-.048-3.299-.06-1.3-.378-2.442-1.295-3.359-.916-.916-2.058-1.234-3.358-1.294C14.445 4.009 14.171 4 12 4z"
        fill="#FFF"
      />
      <path
        d="M12 7.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.774a2.666 2.666 0 110-5.332 2.666 2.666 0 010 5.332z"
        fill="#FFF"
      />
      <circle fill="#FFF" cx={16.27} cy={7.729} r={1} />
    </g>
  </svg>
)

export default SvgInstacolor
