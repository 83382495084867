import React from 'react'

const SvgAvataradultmale = props => (
  <svg viewBox="0 0 29 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#EAC3A2" d="M10.212 14.681h7.66v10.851h-7.66z" />
      <path
        d="M21.063 7.021v5.107a7.03 7.03 0 01-7.022 7.02 7.03 7.03 0 01-7.02-7.02V7.02h14.042z"
        fill="#F1D9C5"
      />
      <path
        d="M7.02 7.02c0-2.114 1.618-4.406 3.61-5.117L15.355.214a.736.736 0 01.884.353l.993 1.986a3.833 3.833 0 013.83 3.83v3.83A3.191 3.191 0 0017.87 7.02h-7.66a3.191 3.191 0 00-3.19 3.192V7.02zM28.085 27.453c0-1.061-.784-2.264-1.756-2.69l-8.457-3.7-3.83 3.83-3.818-3.83-8.466 3.7C.787 25.189 0 26.394 0 27.454v1.907c0 .353.287.64.64.64h26.806c.353 0 .64-.28.64-.64v-1.907z"
        fill="#444"
      />
    </g>
  </svg>
)

export default SvgAvataradultmale
