import React from 'react'

const SvgHealth = props => (
  <svg viewBox="0 0 24 23" {...props}>
    <defs>
      <path
        id="health_svg__a"
        d="M25.217 9A6.77 6.77 0 0132 15.783c0 1.356-.522 2.87-1.409 4.382-2.974 5.583-9.965 11.374-10.33 11.687-.104.052-.209.105-.313.105-.105 0-.209 0-.313-.105-.365-.313-7.357-6.104-10.33-11.687C8.521 18.652 8 17.14 8 15.783A6.77 6.77 0 0114.783 9c2.034 0 3.913.887 5.217 2.452A6.738 6.738 0 0125.217 9zM20 30.757c1.357-1.148 6.626-5.792 9.287-10.279h-5.896l-1.93 2.922c-.104.104-.261.209-.418.209a.475.475 0 01-.417-.209l-3.756-5.635-1.67 2.505c-.104.104-.26.208-.417.208H10.66c2.713 4.487 7.982 9.13 9.339 10.279zm9.913-11.322c.626-1.305 1.044-2.505 1.044-3.652a5.723 5.723 0 00-5.74-5.74c-1.93 0-3.704.94-4.747 2.557a.536.536 0 01-.887 0 5.758 5.758 0 00-4.8-2.557 5.723 5.723 0 00-5.74 5.74c0 1.147.366 2.347 1.044 3.652h4.435l1.878-2.922a.536.536 0 01.887 0l3.756 5.635 1.67-2.505c.104-.104.26-.208.417-.208h6.783z"
      />
    </defs>
    <use transform="translate(-8 -9)" xlinkHref="#health_svg__a" />
  </svg>
)

export default SvgHealth
