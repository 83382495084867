/** @jsx jsx */
import { jsx } from '@emotion/core'
import Text from '../Text'
import PropTypes from 'prop-types'
import InputAddress from '../forms/InputAddress'

const MapFilters = ({
  apiKey,
  addressValue,
  wording,
  onLocationChange,
  doNotLoadGoogleMapsApi,
  filtersComponent,
}) => {
  const styles = {
    wrapper: {
      width: '100%',
      height: '100%',
    },
    selectWrapper: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    inputWrapper: {
      display: 'flex',
      flex: 1,
      marginLeft: '15px',
    },
  }

  return (
    <div css={styles.wrapper}>
      <Text tag="h2" mb={15}>
        {wording.filter_title}
      </Text>
      <InputAddress
        autocompleteAPIKey={apiKey}
        label={wording.address_label}
        value={addressValue}
        onChangeLocation={onLocationChange}
        doNotLoadGoogleMapsApi={doNotLoadGoogleMapsApi}
      />
      <div css={styles.selectWrapper}>{filtersComponent}</div>
    </div>
  )
}

MapFilters.defaultProps = {
  addressValue: '',
  onLocationChange: () => {},
  wording: {
    filter_title: '',
    address_label: '',
  },
  filtersComponent: null,
}

MapFilters.propTypes = {
  apiKey: PropTypes.string.isRequired,
  filtersComponent: PropTypes.node,
  // Input state
  addressValue: PropTypes.string,
  wording: PropTypes.shape({}),
  // Methods
  onLocationChange: PropTypes.func,
}

export default MapFilters
