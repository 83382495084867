/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/core'
import { useContext, useState, useEffect } from 'react'
import Icon from './Icon'
import Text from './Text'
import Button from './Button'
import { useMedia } from 'react-use'

const Clouds = () => {
  const isMobile = useMedia('(max-width: 576px)')
  const { media } = useContext(ThemeContext)

  const styles = {
    cloudsWrapper: {
      position: 'absolute',
      width: '100%',
      top: 150,
      [media.tablet]: {
        top: 0,
      },
    },
  }

  return (
    <div css={styles.cloudsWrapper}>
      <Icon
        name="greycloud"
        size={40}
        css={{ position: 'absolute', top: 150, marginLeft: '5%', zIndex: 0 }}
      />
      {!isMobile && (
        <Icon
          name="greycloud"
          size={70}
          css={{ position: 'absolute', top: 50, marginLeft: '35%', zIndex: 0 }}
        />
      )}
      <Icon
        name="greycloud"
        size={50}
        css={{ position: 'absolute', top: 120, right: '20%', zIndex: 0 }}
      />
    </div>
  )
}

const Details404 = ({ wording }) => {
  const { media } = useContext(ThemeContext)

  const styles = {
    textWrapper: {
      display: 'flex',
      flex: 1,
      zIndex: 1,
      flexDirection: 'column',
      minWidth: 300,
    },
    title: {
      display: 'flex',
      marginBottom: 20,
      alignItems: 'center',
    },
    button: {
      alignSelf: 'center',
      marginTop: 40,
      width: 200,
      [media.mobile]: {
        alignSelf: 'flex-start',
        marginTop: 20,
      },
    },
  }

  return (
    <div css={styles.textWrapper}>
      <div css={styles.title}>
        <Icon name="redcross" size="20" />
        <Text tag="h2" css={{ marginLeft: 10 }}>
          {wording.title}
        </Text>
      </div>
      <Text>{wording.description}</Text>
      <Button ghost css={styles.button} href={'/'}>
        {wording.button}
      </Button>
    </div>
  )
}

const Svg404 = ({ mouseXY }) => {
  const [clientBound, setClientBound] = useState(null)
  let SVGWrapperRef = null

  const svgMiddlePosition = {
    x: clientBound ? clientBound.x + clientBound.width / 2 : 0,
    y: clientBound ? clientBound.y + clientBound.height / 2 : 0,
  }

  const translateX = -(svgMiddlePosition.x - mouseXY.x) * 0.01
  const translateY = -(svgMiddlePosition.y - mouseXY.y) * 0.01

  const styles = {
    svgWrapper: {
      flex: 1,
      position: 'relative',
      height: '250px',
      minWidth: 300,
    },
    mainSvg: {
      position: 'absolute',
    },
    bordersSvg: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: `translateX(${translateX}px) translateY(${translateY}px)`,
    },
  }

  const setRefToWrapper = () => {
    if (SVGWrapperRef) setClientBound(SVGWrapperRef.getBoundingClientRect())
  }

  useEffect(() => {
    window.addEventListener('resize', setRefToWrapper, false)
    return () => {
      window.removeEventListener('resize', setRefToWrapper, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      css={styles.svgWrapper}
      ref={ref => {
        if (ref && !clientBound) {
          SVGWrapperRef = ref
          setClientBound(ref.getBoundingClientRect())
        }
      }}
    >
      <Icon name="notfound" size={'100%'} css={styles.mainSvg} />
      <div css={styles.bordersSvg}>
        <Icon name="notfoundborders" size={'100%'} />
      </div>
    </div>
  )
}

const Page404 = ({ wording }) => {
  const theme = useContext(ThemeContext)
  const [mouseXY, setMouseXY] = useState({ x: 0, y: 0 })

  const styles = {
    wrapper: {
      position: 'relative',
      width: '100%',
      height: '600px',
      backgroundColor: theme.colors.smallLightGray,
    },
    contentWrapper: {
      flex: 1,
      display: 'flex',
      height: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 30px',
    },
  }

  const onMouseMove = evt => {
    setMouseXY({
      x: evt.clientX,
      y: evt.clientY,
    })
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, false)
    return () => {
      window.removeEventListener('mousemove', onMouseMove, false)
    }
  }, [])

  return (
    <div css={styles.wrapper}>
      <Clouds />
      <div css={styles.contentWrapper}>
        <Details404 wording={wording} />
        <Svg404 mouseXY={mouseXY} />
      </div>
    </div>
  )
}
export default Page404
