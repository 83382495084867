import React from 'react'

const SvgRemoveuser = props => (
  <svg viewBox="0 0 22 24" {...props}>
    <defs>
      <path
        id="removeuser_svg__a"
        d="M1.545 15.438h11.162V13.88c0-.873-.518-1.651-1.32-1.983-1.206-.5-2.64-.754-4.261-.754-1.622 0-3.056.254-4.261.754a2.138 2.138 0 00-1.32 1.983v1.557zm12.02.858H.686v-2.415c0-1.221.726-2.311 1.85-2.777 1.31-.543 2.854-.818 4.59-.818 1.735 0 3.28.275 4.59.818a2.993 2.993 0 011.85 2.777v2.415zM7.125 1.27A3.009 3.009 0 004.12 4.275c0 1.589 1.313 3.435 3.006 3.435 1.692 0 3.005-1.846 3.005-3.435A3.009 3.009 0 007.126 1.27zm0 7.298c-2.21 0-3.863-2.266-3.863-4.293A3.869 3.869 0 017.126.411a3.868 3.868 0 013.864 3.864c0 2.027-1.653 4.293-3.864 4.293zm7.989 15.306a.388.388 0 010-.55l2.836-2.836-2.836-2.836a.388.388 0 11.55-.55l2.836 2.836 2.836-2.836a.388.388 0 11.55.55l-2.836 2.836 2.836 2.836a.388.388 0 11-.55.55L18.5 21.038l-2.836 2.836a.388.388 0 01-.55 0z"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#removeuser_svg__a" />
  </svg>
)

export default SvgRemoveuser
