/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { useState, useContext, useEffect } from 'react'
import { jsx, ThemeContext } from '@emotion/core'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Text, Button, InputSelect, UploadButton, InputText } from '../'

const UploadLine = ({
  wording,
  companyCode,
  categories,
  metadatas,
  uploadItem,
  deleteItem,
  updateItem,
  data,
  newNotification,
}) => {
  // Constructor
  const {
    colors: { lightGray },
    media: { tablet },
  } = useContext(ThemeContext)
  const [deleted, setDeleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [itemID, setID] = useState(get(data, 'id', null))
  const [form, setForm] = useState({
    title: get(data, 'title', ''),
    category: get(data, 'id_category', null)
      ? categories.filter(
          category => get(data, 'id_category', null) === category.value
        )[0]
      : null,
    tags:
      data && data.tags && data.tags.length > 0
        ? data.tags.map(tag => ({
            label: tag,
            value: tag,
          }))
        : [],
    file: null,
  })
  const defaultErrors = {
    title: false,
    category: false,
    file: '',
  }
  const [errors, setErrors] = useState(defaultErrors)
  const [uploadedFilename, setFilename] = useState(get(data, 'filename', null))

  // CSS
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      borderBottom: `1px solid ${lightGray}`,
      padding: '15px 0 0 0',
      [tablet]: {
        flexDirection: 'row',
      },
      ':first-of-type': {
        borderTop: `1px solid ${lightGray}`,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginLeft: 0,
      [tablet]: {
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginLeft: 20,
      },
    },
  }

  const sendErrorNotification = () => {
    return newNotification({
      type: 'error',
      message: wording.notification.error,
    })
  }

  const sendSuccessNotification = message => {
    return newNotification({
      type: 'success',
      message: message,
    })
  }

  const checkFormValidation = () => {
    if ((!form.file && !uploadedFilename) || !form.category || !form.title) {
      setErrors({
        ...errors,
        title: !form.title,
        category: !form.category,
        file:
          !form.file && !uploadedFilename ? wording.errors.file_missing : '',
      })
      return false
    }
    return true
  }

  // Methods
  const onUploadItem = async () => {
    try {
      setLoading(true)
      setErrors(defaultErrors)
      if (!checkFormValidation()) return
      const response = await uploadItem({
        variables: {
          companyCode,
          file: form.file,
          info: {
            id_category: form.category.value,
            title: form.title,
            tags: get(form, 'tags', []).map(tag => tag.label),
          },
        },
      })
      if (!response.data.uploadDocument.id) return sendErrorNotification()
      setID(response.data.uploadDocument.id)
      setFilename(response.data.uploadDocument.filename)
      return sendSuccessNotification(wording.notification.successUpload)
    } catch (e) {
      sendErrorNotification()
    } finally {
      setLoading(false)
    }
  }

  const onDeleteItem = async () => {
    try {
      setLoading(true)
      const response = await deleteItem({
        variables: {
          companyCode,
          id: itemID,
        },
      })
      if (!response.data.deleteDocument) return sendErrorNotification()
      setDeleted(true)
      return sendSuccessNotification(wording.notification.successDelete)
    } catch (e) {
      sendErrorNotification()
    } finally {
      setLoading(false)
    }
  }

  const onUpdateItem = async () => {
    try {
      setLoading(true)
      setErrors(defaultErrors)
      if (!checkFormValidation()) return
      const response = await updateItem({
        variables: {
          companyCode,
          id: itemID,
          info: {
            title: form.title,
            id_category: form.category.value,
            tags: get(form, 'tags', []).map(tag => tag.label),
          },
        },
      })
      if (!response.data.updateDocument) return sendErrorNotification()
      return sendSuccessNotification(wording.notification.successUpdate)
    } catch (e) {
      sendErrorNotification()
    } finally {
      setLoading(false)
    }
  }

  // ComponentDidUpdate
  useEffect(() => {
    setLoading(false)
  }, [data])

  // Render
  if (deleted) return null
  return (
    <div css={styles.wrapper}>
      <div css={{ flex: 1, paddingRight: 0, [tablet]: { paddingRight: 20 } }}>
        <UploadButton
          onUpload={file => setForm({ ...form, file })}
          inline
          ghost
          error={errors.file}
          uploadedFilename={uploadedFilename}
        >
          {wording.button}
        </UploadButton>
        <InputText
          placeholder={wording.input_title}
          error={errors.title}
          onChange={({ value }) => setForm({ ...form, title: value })}
          css={{ marginBottom: 20, marginTop: 3 }}
          value={form.title || ''}
        />
      </div>
      <div css={{ flex: 1 }}>
        <InputSelect
          options={categories}
          label={wording.category_input}
          inline
          error={errors.category}
          onChange={value => setForm({ ...form, category: value })}
          defaultValue={form.category || null}
        />
        {metadatas && metadatas.length > 0 && (
          <InputSelect
            options={metadatas.map(metadata => ({
              label: metadata,
              value: metadata,
            }))}
            label={wording.metadata_input}
            isMulti
            inline
            onChange={value => setForm({ ...form, tags: value })}
            defaultValue={form.tags || null}
          />
        )}
      </div>
      <div css={styles.buttonsWrapper}>
        <Button
          icon={loading ? 'spinner' : itemID ? 'update' : 'check'}
          css={{ marginBottom: 20 }}
          onClick={loading ? () => {} : itemID ? onUpdateItem : onUploadItem}
          ghost
        />
        {!loading && itemID && (
          <Button
            icon="trash"
            ghost
            onClick={onDeleteItem}
            css={{
              margin: '0 0 20px 20px',
              [tablet]: {
                margin: '3px 0 20px 0',
              },
            }}
          />
        )}
      </div>
    </div>
  )
}

const UploadItems = ({
  uploadItem,
  deleteItem,
  updateItem,
  wording,
  categories,
  metadatas,
  companyCode,
  itemsUploaded,
  newNotification,
}) => {
  const {
    colors: { label: labelColor },
  } = useContext(ThemeContext)
  const [line, setLine] = useState(1)

  const styles = {
    wrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    buttonWrapper: { width: 200, marginTop: 20, alignSelf: 'center' },
  }

  return (
    <div>
      <Text tag="h2" mb={10}>
        {wording.title}
      </Text>
      <Text tag="p" mb={15} css={{ color: labelColor }}>
        {wording.description}
      </Text>
      <div css={styles.wrapper}>
        {itemsUploaded &&
          itemsUploaded.length > 0 &&
          itemsUploaded.map(item => (
            <UploadLine
              key={item.id}
              companyCode={companyCode}
              wording={wording}
              categories={categories}
              metadatas={metadatas}
              uploadItem={uploadItem}
              deleteItem={deleteItem}
              updateItem={updateItem}
              data={item}
              newNotification={newNotification}
            />
          ))}
        {(() => {
          const UploadLines = []
          for (let index = 0; index < line; index++) {
            UploadLines.push(
              <UploadLine
                key={index}
                companyCode={companyCode}
                wording={wording}
                categories={categories}
                metadatas={metadatas}
                uploadItem={uploadItem}
                deleteItem={deleteItem}
                updateItem={updateItem}
                data={null}
                newNotification={newNotification}
              />
            )
          }
          return UploadLines
        })()}
        <div css={styles.buttonWrapper}>
          <Button
            icon="add"
            iconPosition="left"
            ghost
            onClick={() => {
              setLine(line + 1)
            }}
          >
            {wording.add_more}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UploadItems

UploadItems.defaultProps = {
  uploadItem: () => {},
  updateItem: () => {},
  deleteItem: () => {},
  newNotification: () => {},
  itemsUploaded: [],
}

UploadItems.propTypes = {
  // File action function
  uploadItem: PropTypes.func,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  newNotification: PropTypes.func,
  // Data
  companyCode: PropTypes.string.isRequired,
  itemsUploaded: PropTypes.array,
  categories: PropTypes.array.isRequired,
  metadatas: PropTypes.array.isRequired,
  wording: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    category_input: PropTypes.string.isRequired,
    metadata_input: PropTypes.string.isRequired,
    add_more: PropTypes.string.isRequired,
  }),
}
