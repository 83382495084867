import React from 'react'

const SvgRedcross = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12z"
        fill="#E86C60"
      />
      <path
        d="M12.768 12l3.962-3.962a.543.543 0 10-.768-.768L12 11.232 8.038 7.27a.543.543 0 10-.768.768L11.232 12 7.27 15.962a.543.543 0 10.768.768L12 12.768l3.962 3.962a.541.541 0 00.768 0 .543.543 0 000-.768L12.768 12z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default SvgRedcross
