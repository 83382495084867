/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState, useContext, useEffect } from 'react'
import { jsx, ThemeContext } from '@emotion/core'
import PropTypes from 'prop-types'
import { Button, Text, Icon } from '..'

// prettier-ignore
const FILE_TYPES = ['url', 'csv', 'doc', 'docx', 'pdf', 'pptx', 'txt', 'xlsx', 'zip'];

const UploadButton = ({
  children,
  uploadedFilename,
  inline,
  ghost,
  onUpload,
  error,
}) => {
  const {
    colors: { label: labelColor, error: errorColor },
  } = useContext(ThemeContext)
  const uploadInput = useRef(null)
  const [file, setFile] = useState(null)

  const styles = {
    uploadButtonWrapper: {
      display: 'flex',
      flexDirection: inline ? 'row' : 'column',
      alignItems: inline ? 'center' : 'flex-start',
      marginBottom: 20,
      minHeight: 42,
    },
  }

  // Each time a file is selected by the user
  // trigger onUpload() from parent component to get the file
  useEffect(() => {
    onUpload(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <div css={styles.uploadButtonWrapper}>
      {uploadedFilename ? (
        <Icon
          name={(() => {
            const fileType = uploadedFilename
              .slice(uploadedFilename.lastIndexOf('.') + 1)
              .toLowerCase()
            return FILE_TYPES.indexOf(fileType) > -1 ? fileType : 'file'
          })()}
          size={32}
          css={{ marginRight: 10 }}
        />
      ) : (
        <Button
          icon={'upload'}
          ghost={ghost}
          css={{ marginRight: 10 }}
          onClick={() => {
            if (uploadInput) uploadInput.current.click()
          }}
        >
          {children}
        </Button>
      )}
      {uploadedFilename && (
        <Text css={{ color: labelColor, marginTop: inline ? 0 : 10 }}>
          {uploadedFilename}
        </Text>
      )}
      {!file && error && (
        <Text css={{ color: errorColor, marginTop: inline ? 0 : 10 }}>
          {error}
        </Text>
      )}
      {file && !uploadedFilename && (
        <Text css={{ color: labelColor, marginTop: inline ? 0 : 10 }}>
          {`${
            file.name.length > 12
              ? `${file.name.slice(0, 8)}..${file.name.slice(
                  file.name.lastIndexOf('.')
                )}`
              : file.name
          }`}
        </Text>
      )}
      <input
        ref={uploadInput}
        css={{ display: 'none' }}
        type="file"
        onChange={({
          target: {
            files: [file],
          },
        }) => {
          setFile(file)
        }}
      />
    </div>
  )
}

export default UploadButton

UploadButton.propTypes = {
  onUpload: PropTypes.func.isRequired,
  uploadedFilename: PropTypes.string,
  inline: PropTypes.bool,
  ghost: PropTypes.bool,
  error: PropTypes.string,
}

UploadButton.defaultProps = {
  inline: false,
  ghost: false,
  uploadedFilename: '',
  error: '',
}
