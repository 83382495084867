import React from 'react'

const SvgBook = props => (
  <svg viewBox="0 0 20 24" {...props}>
    <defs>
      <path
        id="book_svg__a"
        d="M13.632 8h6.225a.52.52 0 01.519.522v8.87a.522.522 0 01-.519.521c-.1 0-.2-.03-.288-.088l-2.825-1.894-2.825 1.894a.517.517 0 01-.806-.434v-8.87A.52.52 0 0113.632 8zm5.706 8.417V9.043H14.15v7.374l2.306-1.547a.517.517 0 01.576 0l2.306 1.547zM10 31.478V8.522A.52.52 0 0110.519 8a.52.52 0 01.519.522v22.435h17.639V9.043H22.97a.52.52 0 01-.519-.521A.52.52 0 0122.97 8h6.225a.52.52 0 01.52.522v22.956a.52.52 0 01-.52.522H10.52a.52.52 0 01-.519-.522z"
      />
    </defs>
    <use transform="translate(-10 -8)" xlinkHref="#book_svg__a" />
  </svg>
)

export default SvgBook
