import React from 'react'

const SvgGreencheck = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M8 0a8 8 0 108 8 8.023 8.023 0 00-8-8zM6.667 11.61L3.057 8 4 7.057l2.667 2.667L12 4.391l.943.942-6.276 6.276z"
      fill="#119DA4"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgGreencheck
