import React from 'react'

const SvgLoader = props => (
  <svg viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#444">
      <path d="M20 9v4" />
      <path d="M27.778 12.222L24.95 15.05" opacity={0.9} />
      <path d="M31 20h-4" opacity={0.8} />
      <path d="M27.778 27.778L24.95 24.95" opacity={0.7} />
      <path d="M20 31v-4" opacity={0.6} />
      <path d="M12.222 27.778l2.828-2.828" opacity={0.5} />
      <path d="M9 20h4" opacity={0.4} />
      <path d="M12.222 12.222l2.828 2.828" opacity={0.3} />
    </g>
  </svg>
)

export default SvgLoader
