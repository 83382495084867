import React from 'react'

const SvgAvataradultf = props => (
  <svg viewBox="0 0 26 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M4.399 26.17V8.298C4.399 3.715 8.056 0 12.568 0c4.511 0 8.168 3.715 8.168 8.298V26.17H4.4z"
        fill="#444"
      />
      <path fill="#EAC3A2" d="M8.797 14.681h7.541v9.574H8.797z" />
      <path
        d="M23.05 24.474l-6.712-3.41a6.245 6.245 0 01-3.77 1.276 6.187 6.187 0 01-3.76-1.276l-6.724 3.41A3.836 3.836 0 000 27.899v1.46c0 .354.282.641.634.641H24.5c.35 0 .634-.283.634-.642v-1.46c0-1.45-.807-2.776-2.084-3.424z"
        fill="#8680D8"
      />
      <path
        d="M5.655 10.213v1.276c0 3.872 3.101 7.022 6.913 7.022 3.81 0 6.912-3.15 6.912-7.022v-1.276c0-1.058-.844-1.915-1.885-1.915-2.189 0-4.087-1.263-5.027-3.11-.94 1.847-2.839 3.11-5.027 3.11-1.042 0-1.886.857-1.886 1.915z"
        fill="#F1D9C5"
      />
      <path
        d="M7.25 21.855l1.222 2.87c.161.4.658.525.984.25l3.112-2.635a6.187 6.187 0 01-3.76-1.276l-1.559.79zM16.338 21.064a6.245 6.245 0 01-3.77 1.276l3.11 2.634c.327.276.824.15.985-.248l1.225-2.875-1.55-.787z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgAvataradultf
