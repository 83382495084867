import React from 'react'

const SvgUpload = props => (
  <svg viewBox="0 0 24 17" {...props}>
    <defs>
      <path
        id="upload_svg__a"
        d="M23.5 21.544a.521.521 0 11-.738.738l-2.24-2.24v7.088a.521.521 0 11-1.044 0v-7.088l-2.24 2.24a.521.521 0 11-.738-.738l3.13-3.13a.525.525 0 01.74 0l3.13 3.13zm4.255-2.218A4.703 4.703 0 0132 24a4.7 4.7 0 01-4.696 4.696h-3.13a.521.521 0 110-1.044h3.13A3.657 3.657 0 0030.957 24a3.656 3.656 0 00-3.63-3.652.522.522 0 01-.57-.485c-.25-3.824-3.449-6.82-7.279-6.82a7.288 7.288 0 00-7.285 6.95.522.522 0 01-.393.48A3.642 3.642 0 009.043 24a3.657 3.657 0 003.653 3.652h3.13a.521.521 0 110 1.044h-3.13A4.7 4.7 0 018 24a4.682 4.682 0 013.176-4.429A8.33 8.33 0 0119.478 12c4.22 0 7.77 3.182 8.277 7.326z"
      />
    </defs>
    <use transform="translate(-8 -12)" xlinkHref="#upload_svg__a" />
  </svg>
)

export default SvgUpload
