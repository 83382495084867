import React from 'react'

const SvgCog = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="cog_svg__a"
        d="M20 16.348A3.657 3.657 0 0123.652 20 3.657 3.657 0 0120 23.652 3.657 3.657 0 0116.348 20 3.657 3.657 0 0120 16.348zm0 6.26A2.612 2.612 0 0022.609 20 2.612 2.612 0 0020 17.391 2.612 2.612 0 0017.391 20 2.612 2.612 0 0020 22.609zm11.522-4.693c.27.023.478.248.478.52v3.13a.52.52 0 01-.478.52l-2.969.247a8.788 8.788 0 01-.857 2.064l1.926 2.275a.522.522 0 01-.03.707l-2.213 2.214a.521.521 0 01-.706.03l-2.276-1.926a8.806 8.806 0 01-2.064.857l-.248 2.969a.522.522 0 01-.52.478h-3.13a.521.521 0 01-.52-.478l-.247-2.969a8.806 8.806 0 01-2.064-.857l-2.277 1.926a.522.522 0 01-.705-.03l-2.214-2.214a.522.522 0 01-.03-.706l1.926-2.275a8.788 8.788 0 01-.857-2.064l-2.969-.248a.521.521 0 01-.478-.52v-3.13c0-.272.208-.497.478-.52l2.97-.248c.195-.72.482-1.412.856-2.064l-1.925-2.276a.522.522 0 01.029-.706l2.213-2.213a.522.522 0 01.706-.03l2.276 1.926a8.806 8.806 0 012.064-.857l.248-2.969a.522.522 0 01.52-.478h3.13c.272 0 .497.208.52.478l.247 2.97c.72.195 1.412.483 2.064.856l2.277-1.926a.523.523 0 01.705.03l2.214 2.213c.192.192.205.5.03.706l-1.926 2.276c.373.652.66 1.344.857 2.064l2.969.248zm-.565 3.17v-2.17l-2.863-.24a.521.521 0 01-.466-.404 7.745 7.745 0 00-1.013-2.44.522.522 0 01.042-.616l1.858-2.196-1.535-1.534-2.195 1.858a.524.524 0 01-.616.042 7.765 7.765 0 00-2.44-1.013.523.523 0 01-.405-.466l-.239-2.863h-2.17l-.239 2.864a.522.522 0 01-.405.466 7.76 7.76 0 00-2.44 1.013.524.524 0 01-.616-.043l-2.195-1.858-1.535 1.535 1.858 2.195a.522.522 0 01.042.616 7.772 7.772 0 00-1.013 2.44.522.522 0 01-.466.405l-2.863.239v2.17l2.863.239a.521.521 0 01.466.405 7.745 7.745 0 001.013 2.44.523.523 0 01-.042.616l-1.858 2.195 1.535 1.535 2.195-1.858a.522.522 0 01.616-.042 7.765 7.765 0 002.44 1.013c.222.05.387.239.405.466l.239 2.863h2.17l.239-2.864a.522.522 0 01.405-.466 7.76 7.76 0 002.44-1.013.522.522 0 01.616.043l2.195 1.858 1.535-1.535-1.858-2.195a.523.523 0 01-.042-.616 7.767 7.767 0 001.013-2.44.522.522 0 01.466-.405l2.863-.239z"
      />
    </defs>
    <use transform="translate(-8 -8)" xlinkHref="#cog_svg__a" />
  </svg>
)

export default SvgCog
