/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/core'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import Select from 'react-select'

const customStyles = (theme, error, inline) => ({
  control: provided => ({
    ...provided,
    ...(inline ? { width: 260 } : {}),
    minHeight: '45px',
    borderColor: error ? theme.colors.error : theme.colors.lightGray,
    borderRadius: '3px',
    boxShadow: 'none',
    ':hover': {
      borderColor: error ? theme.colors.error : theme.colors.lightGray,
    },
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '3px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 15px',
    fontSize: '16px',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    color: state.isDisabled ? theme.colors.gray : theme.colors.text,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: '0',
    color: state.isDisabled ? theme.colors.gray : theme.colors.text,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    fontSize: '16px',
    backgroundColor: state.isSelected
      ? theme.colors.primary
      : state.isFocused
      ? theme.colors.lightGray
      : 'none',
    color: state.isSelected ? theme.colors.white : theme.colors.text,
  }),
  singleValue: provided => ({
    ...provided,
    padding: '2px 0',
  }),
})

function InputSelect({
  // we prevent sending the value from the rest obj to prevent conflit between studio & react-select
  // eslint-disable-next-line
  value,
  error,
  id,
  label,
  theme,
  options,
  inline,
  className,
  ...rest
}) {
  const { media, colors } = useContext(ThemeContext)

  const styles = {
    wrapper: inline
      ? {
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 20,
          [media.tablet]: {
            maxWidth: '400px',
          },
        }
      : {
          display: 'inline-block',
          width: '100%',
          minWidth: '240px',
          [media.tablet]: {
            maxWidth: '330px',
          },
        },
    label: {
      display: inline ? 'block' : 'flex',
      margin: inline ? '0 15px 0 0' : '0 0 15px 1px',
      fontSize: '15px',
      color: error ? colors.error : colors.text,
    },
  }

  return (
    <div css={styles.wrapper} className={className}>
      {label && (
        <label css={styles.label} error={error} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        styles={customStyles(theme, error, inline)}
        options={options}
        {...rest}
      />
    </div>
  )
}

InputSelect.propTypes = {
  /**
   * input value
   */
  label: PropTypes.string,

  /**
   * input ID
   */
  id: PropTypes.string,

  /**
   * error
   */
  error: PropTypes.bool,

  /**
   * onChange function
   */
  onChange: PropTypes.func,

  /**
   * placeholder
   */
  placeholder: PropTypes.string,

  /**
   * label
   */
  options: PropTypes.arrayOf(PropTypes.object),

  /**
   * disabled input
   */
  isDisabled: PropTypes.bool,

  /**
   * inline label input
   */
  inline: PropTypes.bool,
}

InputSelect.defaultProps = {
  inline: false,
}

export default withTheme(InputSelect)
