import React from 'react'

const SvgXlsx = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path d="M44 27H4l-3-4 4-4h38l4 4z" fill="#0e512b" />
    <path
      d="M41 47H7a2 2 0 01-2-2V3a2 2 0 012-2h24l12 12v32a2 2 0 01-2 2z"
      fill="#e6e6e6"
    />
    <path d="M31 1v10a2 2 0 002 2h10z" fill="#b3b3b3" />
    <path d="M45 41H3a2 2 0 01-2-2V23h46v16a2 2 0 01-2 2z" fill="#1b7343" />
    <g fill="#fff">
      <path d="M17.592 36h-2.455l-1.531-2.455L12.092 36H9.691l2.605-4.018-2.449-3.835H12.2l1.418 2.428 1.364-2.428h2.422l-2.487 4.001zM18.473 36v-7.853h2.122v6.14h3.024V36zM30.01 33.615c0 .487-.124.919-.371 1.297s-.604.672-1.069.881c-.465.21-1.012.314-1.638.314-.523 0-.961-.037-1.316-.11s-.723-.201-1.106-.384v-1.891c.405.208.825.37 1.262.486s.838.174 1.203.174c.315 0 .546-.054.693-.163s.22-.25.22-.422a.473.473 0 00-.088-.282 1.046 1.046 0 00-.285-.244c-.13-.082-.479-.251-1.044-.505-.512-.232-.896-.458-1.152-.677s-.446-.469-.569-.752a2.485 2.485 0 01-.186-1.004c0-.724.263-1.288.79-1.692s1.25-.606 2.17-.606c.813 0 1.642.188 2.487.563l-.65 1.638c-.734-.336-1.368-.504-1.901-.504-.276 0-.476.048-.602.145s-.188.217-.188.359c0 .154.08.292.239.414s.591.344 1.297.666c.677.305 1.146.631 1.41.98.262.35.394.789.394 1.319zM38.298 36h-2.455l-1.531-2.455L32.798 36h-2.401l2.605-4.018-2.449-3.835h2.353l1.418 2.428 1.364-2.428h2.422l-2.487 4.001z" />
    </g>
  </svg>
)

export default SvgXlsx
