import React from 'react'

const SvgUpdate = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      data-cap="butt"
      fill="none"
      stroke="#444"
      strokeWidth={2}
      strokeMiterlimit={10}
      d="M2 12C2 6.5 6.5 2 12 2c3.9 0 7.3 2.2 8.9 5.5"
    />
    <path
      data-cap="butt"
      data-color="color-2"
      fill="none"
      stroke="#444"
      strokeWidth={2}
      strokeMiterlimit={10}
      d="M22 12c0 5.5-4.5 10-10 10-3.9 0-7.3-2.2-8.9-5.5"
    />
    <path
      fill="none"
      stroke="#444"
      strokeWidth={2}
      strokeLinecap="square"
      strokeMiterlimit={10}
      d="M21.8 1.7L21 7.6l-6-.8"
    />
    <path
      data-color="color-2"
      fill="none"
      stroke="#444"
      strokeWidth={2}
      strokeLinecap="square"
      strokeMiterlimit={10}
      d="M2.2 22.3l.8-5.9 6 .8"
    />
  </svg>
)

export default SvgUpdate
