import React from 'react'

const SvgClock = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="clock_svg__a"
        d="M23.984 15.468a.387.387 0 11.548.548l-2.919 2.918c.203.307.322.672.322 1.066A1.938 1.938 0 0120 21.935 1.938 1.938 0 0118.065 20c0-.394.12-.76.322-1.066l-4.854-4.854a.387.387 0 11.547-.547l4.854 4.854a1.923 1.923 0 012.132 0l2.918-2.919zM20 21.161c.64 0 1.161-.52 1.161-1.161 0-.64-.52-1.161-1.161-1.161-.64 0-1.161.52-1.161 1.161 0 .64.52 1.161 1.161 1.161zM20 8c6.617 0 12 5.383 12 12s-5.383 12-12 12S8 26.617 8 20 13.383 8 20 8zm0 23.226c6.19 0 11.226-5.036 11.226-11.226 0-6.19-5.036-11.226-11.226-11.226C13.81 8.774 8.774 13.81 8.774 20c0 6.19 5.036 11.226 11.226 11.226zm0-18.968a.387.387 0 01-.387-.387V10.71a.387.387 0 11.774 0v1.161a.387.387 0 01-.387.387zM27.742 20c0-.214.173-.387.387-.387h1.161a.387.387 0 110 .774H28.13a.387.387 0 01-.387-.387zM20 27.742c.214 0 .387.173.387.387v1.161a.387.387 0 11-.774 0V28.13c0-.214.173-.387.387-.387zm-8.129-8.13a.387.387 0 110 .775H10.71a.387.387 0 110-.774h1.161z"
      />
    </defs>
    <use transform="translate(-8 -8)" xlinkHref="#clock_svg__a" />
  </svg>
)

export default SvgClock
