import React from 'react'

const SvgTwitterwhite = props => (
  <svg viewBox="0 0 24 20" {...props}>
    <defs>
      <path
        d="M24 2.557a9.843 9.843 0 01-2.828.775A4.938 4.938 0 0023.337.608a9.858 9.858 0 01-3.127 1.195 4.924 4.924 0 00-8.39 4.49A13.979 13.979 0 011.67 1.15a4.9 4.9 0 00-.666 2.476c0 1.708.87 3.215 2.19 4.098a4.904 4.904 0 01-2.23-.616v.062a4.927 4.927 0 003.95 4.828 4.933 4.933 0 01-2.224.084 4.929 4.929 0 004.6 3.42 9.88 9.88 0 01-6.115 2.108c-.398 0-.79-.024-1.175-.069a13.937 13.937 0 007.548 2.212c9.057 0 14.01-7.503 14.01-14.01 0-.213-.005-.425-.015-.636A10.007 10.007 0 0024 2.556z"
        id="twitterwhite_svg__a"
      />
    </defs>
    <use fill="#FFF" fillRule="nonzero" xlinkHref="#twitterwhite_svg__a" />
  </svg>
)

export default SvgTwitterwhite
