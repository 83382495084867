/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/core'
import React from 'react'
import PropTypes from 'prop-types'

import FormInfos from '../FormInfos'
import Slice from '../../slices/Slice'

export default function Lists({ lists, modules }) {
  const theme = React.useContext(ThemeContext)

  const styles = {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      [theme.media.laptop]: {
        flexDirection: 'row',
      },
    },
    lists: {
      flex: 1,
      flexDirection: 'column',
      [theme.media.laptop]: {
        paddingRight: 10,
      },
    },
    modules: {
      flex: 1,
      display: !modules || modules.length === 0 ? 'none' : 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.media.laptop]: {
        alignItems: 'flex-end',
      },
    },
  }

  return (
    <Slice>
      <div css={styles.wrapper}>
        <div css={styles.lists}>
          {Array.isArray(lists) &&
            lists.map((itemInfo, key) => <FormInfos key={key} {...itemInfo} />)}
        </div>
        {Array.isArray(modules) && modules.length > 0 && (
          <div css={styles.modules}>
            {modules.map((Module, key) => (
              <React.Fragment key={key}>{Module}</React.Fragment>
            ))}
          </div>
        )}
      </div>
    </Slice>
  )
}

Lists.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape(FormInfos.propTypes)),
  modules: PropTypes.arrayOf(PropTypes.element),
}
