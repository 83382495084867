import React from 'react'

const SvgNotfoundborders = props => (
  <svg
    id="notfoundborders_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 410 220"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.notfoundborders_svg__st2{fill:#2f2e41}.notfoundborders_svg__st2,.notfoundborders_svg__st3,.notfoundborders_svg__st4{display:inline}.notfoundborders_svg__st3{fill:#e86c60}.notfoundborders_svg__st4{opacity:.1;enable-background:new}.notfoundborders_svg__st5,.notfoundborders_svg__st6{display:inline;fill:#3f3d56}.notfoundborders_svg__st6{fill:#fbbebe}.notfoundborders_svg__st8{fill:#57b894}.notfoundborders_svg__st9{fill:none;stroke:#3f3d56}.notfoundborders_svg__st10{fill:#fff}.notfoundborders_svg__st11{opacity:.3;fill:#e86c60;enable-background:new}'
      }
    </style>
    <g transform="translate(1 1)">
      <path
        className="notfoundborders_svg__st9"
        d="M122.5 162.8h-13.3v-39.6c0-2.1-.8-4.1-2.3-5.6-1.5-1.5-3.5-2.3-5.6-2.3h-3.1c-4.3 0-7.9 3.5-7.9 7.9v39.6H52.7c-1.6 0-3-.8-3.8-2.2-.8-1.4-.9-3-.1-4.4l39.8-75.9c1-1.9 1.2-4.2.5-6.2-.7-2.1-2.2-3.7-4.1-4.7l-2.3-1.1c-3.8-1.8-8.3-.3-10.3 3.4l-48.5 91.5c-.8 1.6-1.3 3.3-1.3 5.1 0 5.9 4.8 10.7 10.7 10.7h57.1v25c0 2.5 1 4.9 2.8 6.7s4.2 2.8 6.7 2.8c5.2 0 9.4-4.3 9.4-9.5v-25h13.3c2.1 0 4.2-.9 5.7-2.4s2.4-3.6 2.4-5.7-.8-4.2-2.4-5.7-3.7-2.4-5.8-2.4zM370.5 162.8h-13.3v-39.6c0-4.4-3.5-7.9-7.9-7.9h-3.1c-2.1 0-4.1.8-5.6 2.3s-2.3 3.5-2.3 5.6v39.6h-37.6c-1.6 0-3-.8-3.8-2.2-.8-1.4-.9-3-.1-4.4l39.8-75.9c1-1.9 1.2-4.2.5-6.2-.7-2.1-2.2-3.7-4.1-4.7l-2.3-1.1c-3.8-1.8-8.3-.3-10.3 3.4l-48.5 91.5c-.8 1.6-1.3 3.3-1.3 5.1 0 5.9 4.8 10.7 10.7 10.7h57.1v25c0 5.2 4.2 9.5 9.4 9.5s9.4-4.3 9.4-9.5v-25h13.3c2.1 0 4.2-.9 5.7-2.4s2.4-3.6 2.4-5.7c-.1-4.5-3.7-8.1-8.1-8.1zM197.3 61.5c-32.5 0-55.8 20.8-55.8 69.4 0 55 23.3 69.6 55.8 69.6s57.2-16 57.2-69.6c0-57.4-24.7-69.4-57.2-69.4zm.2 120.7c-22.5 0-34.3-13.2-34.3-51.3 0-33.6 12.2-51.4 34.7-51.4s34.7 11.7 34.7 51.4c.1 37.2-12.6 51.3-35.1 51.3zM391 204.5c-.7-3.4 2.2-6.5 5.1-8.5s6.2-3.9 7.2-7.3c1.4-4.8-2.8-9.3-6-13.1-2.4-2.8-4.5-6-6.2-9.3-.7-1.3-1.3-2.7-1.5-4.2-.3-2.1.4-4.3 1.1-6.3 2.3-6.8 4.7-13.6 7.3-20.3"
      />
      <path
        className="notfoundborders_svg__st9"
        d="M381.8 157c3.2 1.2 6.8.2 8.9-2.5s2.4-6.3.6-9.2-5.2-4.4-8.5-3.7l.2 4.1-1.9-3.5c-1.9.9-3.4 2.5-4.1 4.5-.3.7-.4 1.4-.5 2.2-.2 3.6 1.9 6.9 5.3 8.1zM390 132.8c.9-2.6 3.1-4.5 5.8-5.1l.7 3.9 1.2-4.1c3.5.1 6.5 2.3 7.5 5.7s-.2 6.9-3.1 8.9-6.7 1.9-9.4-.3c-2.8-2.1-3.9-5.8-2.7-9zM395.8 175c3.8 1.4 8.1-.3 9.9-4s.6-8.1-2.8-10.3l-.9 3.2-.7-4c-2.7-1-5.7-.4-7.9 1.4-2.2 1.8-3.2 4.7-2.7 7.5s2.4 5.2 5.1 6.2zM387.6 187.9c.7 3.7 3.8 6.5 7.6 6.6s7.1-2.4 8-6.1c.9-3.7-.9-7.5-4.3-9.1-3.4-1.6-7.5-.7-9.8 2.3l3.6 5.4-4.9-2.8c-.3 1.1-.4 2.4-.2 3.7zM11.4 207.5c-.4-2.2 1.4-4.2 3.3-5.5s4-2.6 4.6-4.8c.9-3.2-1.8-6-3.9-8.5-1.5-1.9-2.9-3.9-4-6.1-.5-.9-.8-1.8-.9-2.8-.2-1.4.3-2.8.7-4.1 1.5-4.4 3-8.9 4.7-13.2M4.8 176.2c2 .7 4.2.1 5.6-1.5 1.3-1.6 1.5-4 .4-5.8s-3.2-2.7-5.3-2.3l.1 2.6-1.2-2.2c-1.2.6-2.1 1.6-2.6 2.8-.2.4-.3.9-.3 1.4-.1 2.2 1.2 4.2 3.3 5z"
      />
      <path
        className="notfoundborders_svg__st9"
        d="M10.8 160.8c.6-1.6 1.9-2.8 3.6-3.2l.4 2.4.8-2.6c2.2 0 4.1 1.5 4.7 3.5.6 2.1-.2 4.3-1.9 5.6s-4.2 1.2-5.9-.2-2.4-3.5-1.7-5.5zM14.8 188.2c2.4.9 5-.2 6.2-2.5 1.1-2.3.4-5-1.8-6.4l-.6 2-.4-2.5c-2.6-.9-5.5.4-6.4 3-.9 2.6.4 5.5 3 6.4zM9.6 197.4c.4 2.3 2.4 4.1 4.8 4.1s4.5-1.5 5-3.8-.6-4.7-2.7-5.7-4.7-.4-6.1 1.5l2.2 3.4-3-1.8c-.3.7-.4 1.5-.2 2.3z"
      />
      <ellipse
        className="notfoundborders_svg__st9"
        cx={183}
        cy={119}
        rx={6.5}
        ry={8.5}
      />
      <ellipse
        className="notfoundborders_svg__st9"
        cx={217}
        cy={119}
        rx={6.5}
        ry={8.5}
      />
      <ellipse
        className="notfoundborders_svg__st9"
        cx={200.5}
        cy={144}
        rx={12}
        ry={6.5}
      />
      <path
        className="notfoundborders_svg__st10"
        d="M203.5 143.7c0 .5-.2.9-.5 1.3-.3.3-.7.5-1.2.5h-3.7c-.9 0-1.7-.8-1.7-1.8 0-.4-.1-.8.3-1 .9-.2 1.6-.3 2.5-.3.7 0 1.5-.1 2.2 0 .7.1 1.1.1 1.9.7.2.1.2.4.2.6z"
      />
      <circle
        className="notfoundborders_svg__st10"
        cx={183.5}
        cy={119.5}
        r={2}
      />
      <circle
        className="notfoundborders_svg__st10"
        cx={216.5}
        cy={119.5}
        r={2}
      />
      <circle
        className="notfoundborders_svg__st11"
        cx={221.5}
        cy={133.5}
        r={2}
      />
      <circle
        className="notfoundborders_svg__st11"
        cx={175.5}
        cy={133.5}
        r={2}
      />
    </g>
    <path d="M2.5 1.5h405v220H2.5V1.5z" fill="none" />
  </svg>
)

export default SvgNotfoundborders
