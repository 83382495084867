import React from 'react'

const SvgMailrecall = props => (
  <svg viewBox="0 0 60 60" {...props}>
    <path d="M58.199 42.52a1.002 1.002 0 00-1.18.781c-1.116 5.502-5.445 9.199-10.771 9.199-5.028 0-9.446-3.3-10.948-8H40v-2h-8v8h2v-3.848c2.169 4.681 6.915 7.848 12.249 7.848 6.304 0 11.42-4.341 12.731-10.801a1 1 0 00-.781-1.179zM58 30.5v4.308c-2.25-4.454-6.704-7.308-11.751-7.308-6.109 0-11.541 3.997-13.209 9.721a1 1 0 101.92.558c1.398-4.797 6.146-8.279 11.289-8.279 4.565 0 8.562 2.766 10.33 7H52v2h8v-8h-2z" />
    <path d="M25.369 29.353c1.002.894 2.317 1.341 3.633 1.341 1.313 0 2.626-.446 3.625-1.337L56 8.718V26.5a1 1 0 102 0v-20c0-.008-.004-.015-.005-.023a.996.996 0 00-.06-.33l-.011-.019c-.011-.027-.029-.049-.042-.075a.986.986 0 00-.148-.22c-.026-.029-.054-.052-.083-.078a.98.98 0 00-.202-.136c-.033-.017-.063-.035-.098-.048A.985.985 0 0057 5.5H1a.985.985 0 00-.351.071c-.035.013-.065.031-.098.048a.987.987 0 00-.203.136c-.029.026-.056.049-.082.078a.963.963 0 00-.149.221c-.013.025-.031.047-.042.074l-.011.018a.996.996 0 00-.06.33c0 .009-.004.016-.004.024v39l.002.009c.001.107.017.214.053.319.004.012.013.022.018.034.013.034.034.063.051.095a.883.883 0 00.225.288c.06.053.126.096.197.133.034.018.066.037.102.051A.99.99 0 001 46.5h27a1 1 0 100-2H3.879c4.548-3.604 14.801-11.781 20.069-16.403l1.421 1.256zM54.356 7.5L31.299 27.86c-1.267 1.132-3.331 1.132-4.602-.004l-1.979-1.747c-.006-.007-.007-.015-.013-.021-.022-.025-.053-.035-.077-.057L3.644 7.5h50.712zM2 43.437V8.718l20.437 18.046C16.881 31.617 6.086 40.204 2 43.437z" />
  </svg>
)

export default SvgMailrecall
