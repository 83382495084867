import React from 'react'

const SvgAvatarchildm = props => (
  <svg viewBox="0 0 38 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#EAC3A2" d="M13.585 19.623h10.566v14.34H13.585z" />
      <path
        d="M28.68 9.811v6.674c0 5.059-4.233 9.175-9.435 9.175-5.201 0-9.434-4.116-9.434-9.175V9.81H28.68z"
        fill="#F1D9C5"
      />
      <path
        d="M9.811 9.265c0-2.862 2.175-5.965 4.85-6.927L21.01.053A.985.985 0 0122.2.53l1.334 2.689c2.842 0 5.146 2.326 5.146 5.183v5.183c0-2.386-1.92-4.32-4.288-4.32H14.1c-2.368 0-4.288 1.934-4.288 4.32v-4.32z"
        fill="#444"
      />
      <path
        d="M37.736 36.558c0-1.434-1.054-3.06-2.36-3.634l-11.362-5-5.148 5.176-5.128-5.175-11.376 5C1.057 33.497 0 35.125 0 36.557v2.577c0 .478.386.865.86.865h36.017c.474 0 .859-.378.859-.865v-2.577z"
        fill="#357690"
      />
    </g>
  </svg>
)

export default SvgAvatarchildm
