/** @jsx jsx */
import { jsx, ThemeContext } from '@emotion/core'
import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'
import Button from './Button'

function Detail({ label, value, info, color }) {
  const theme = React.useContext(ThemeContext)

  const styles = {
    element: {
      marginBottom: 20,

      [theme.media.tablet]: {
        float: 'left',
        width: 105.6,
        marginBottom: 0, // reset mobile margin
        '&:not(:last-of-type)': {
          marginRight: 25,
        },
      },

      [theme.media.laptop]: {
        width: 141.6,
        '&:not(:last-of-type)': {
          marginRight: 40,
        },
      },

      [theme.media.desktop]: {
        width: 165.6,
        '&:not(:last-of-type)': {
          marginRight: 50,
        },
      },
    },
    label: {
      display: 'block',
      marginBottom: 5,

      [theme.media.desktop]: {
        marginBottom: 15,
      },
    },
    value: {
      display: 'block',
      color: theme.colors[color] || color,
    },
    info: {
      display: 'block',
      marginTop: 5,

      [theme.media.tablet]: {
        marginTop: 10,
      },
    },
  }

  return (
    <li css={styles.element}>
      <Text css={styles.label} size="typo5">
        {label}
      </Text>
      <Text css={styles.value} size="typo3">
        {value}
      </Text>
      {info && (
        <Text css={styles.info} size="typo6">
          {info}
        </Text>
      )}
    </li>
  )
}

function Actions({ actions }) {
  const theme = React.useContext(ThemeContext)

  if (!actions || actions.length <= 0) return null

  const styles = {
    wrapper: {
      marginTop: 20,
    },
    buttonWrapper: {
      display: 'block',
      marginTop: 10,

      [theme.media.tablet]: {
        display: 'inline-block',
        marginTop: 0, // reset mobile margin
        '&:not(:first-of-type)': {
          marginLeft: 15,
        },
      },

      [theme.media.desktop]: {
        '&:not(:first-of-type)': {
          marginLeft: 20,
        },
      },
    },
    button: {
      width: '100%',

      [theme.media.tablet]: {
        width: 'auto',
      },
    },
  }

  return (
    <div css={styles.wrapper}>
      {actions.map((action, key) => (
        <span key={key} css={styles.buttonWrapper}>
          <Button css={styles.button} {...action} />
        </span>
      ))}
    </div>
  )
}

export default function ContractDetails({ details, actions }) {
  const theme = React.useContext(ThemeContext)

  if (!details || details.length <= 0) return null

  const styles = {
    wrapper: {
      margin: 15,
      [theme.media.tablet]: {
        margin: 0,
      },
    },
    list: {
      border: '1px solid #e2e2e2',
      boxShadow: '0 1px 10px 0 rgba(0,0,0,0.04)',
      padding: '20px 40px',
      backgroundColor: '#fff',

      // reset list
      boxSizing: 'border-box',
      listStyleType: 'none',
      margin: 0,
      overflow: 'hidden', // counter float

      [theme.media.tablet]: {
        padding: '20px 30px',
      },

      [theme.media.laptop]: {},

      [theme.media.desktop]: {
        padding: '25px 40px',
      },
    },
    action: {
      margin: '15px 15px 15px 0',
      [theme.media.laptop]: {
        margin: '0 0 0 15px',
      },
    },
  }

  return (
    <div css={styles.wrapper}>
      <ul css={styles.list}>
        {details.map((detail, key) => (
          <Detail key={key} {...detail} />
        ))}
      </ul>
      {actions && <Actions actions={actions} />}
    </div>
  )
}

ContractDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
      ]),
      color: PropTypes.string,
      info: PropTypes.any,
    })
  ),

  actions: PropTypes.array,
}
