import React from 'react'

const SvgNotfound = props => (
  <svg
    id="notfound_svg__Calque_1"
    x={0}
    y={0}
    viewBox="0 0 410 220"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.notfound_svg__st1{fill:#2f2e41}.notfound_svg__st2{fill:#e86c60}.notfound_svg__st3{opacity:.1;enable-background:new}.notfound_svg__st4{fill:#3f3d56}.notfound_svg__st5{fill:#fbbebe}.notfound_svg__st6{fill:#57b894}.notfound_svg__st9{fill:none;stroke:#3f3d56}.notfound_svg__st10,.notfound_svg__st11{display:inline;fill:#fff}.notfound_svg__st11{opacity:.3;fill:#e86c60;enable-background:new}'
      }
    </style>
    <ellipse cx={206.5} cy={207} rx={199} ry={13} fill="#f2f2f2" />
    <path
      className="notfound_svg__st1"
      d="M198.3 63c-32.5 0-55.8 20.8-55.8 69.4 0 55 23.3 69.6 55.8 69.6s57.2-16 57.2-69.6c0-57.4-24.7-69.4-57.2-69.4zm.2 120.7c-22.5 0-34.3-13.2-34.3-51.3 0-33.6 12.2-51.4 34.7-51.4s34.7 11.7 34.7 51.4c.1 37.2-12.6 51.3-35.1 51.3z"
    />
    <path
      className="notfound_svg__st2"
      d="M123.5 164.3h-13.3v-39.6c0-4.4-3.5-7.9-7.9-7.9h-3.1c-2.1 0-4.1.8-5.6 2.3s-2.3 3.5-2.3 5.6v39.6H53.7c-1.6 0-3-.8-3.8-2.2s-.9-3-.1-4.4l39.8-75.9c1-1.9 1.2-4.2.5-6.2s-2.2-3.7-4.1-4.7l-2.3-1.1c-3.8-1.8-8.3-.3-10.3 3.4l-48.5 91.5c-.8 1.6-1.3 3.3-1.3 5.1 0 2.8 1.1 5.6 3.1 7.6s4.7 3.1 7.5 3.1h57.1v25c0 2.5 1 4.9 2.8 6.7s4.2 2.8 6.7 2.8 4.9-1 6.7-2.8c1.8-1.8 2.8-4.2 2.8-6.7v-25h13.3c4.4 0 8-3.6 8-8.1-.1-4.5-3.7-8.1-8.1-8.1zM371.5 164.3h-13.3v-39.6c0-4.4-3.5-7.9-7.9-7.9h-3.1c-2.1 0-4.1.8-5.6 2.3s-2.3 3.5-2.3 5.6v39.6h-37.6c-1.6 0-3-.8-3.8-2.2s-.9-3-.1-4.4l39.8-75.9c1-1.9 1.2-4.2.5-6.2s-2.2-3.7-4.1-4.7l-2.3-1.1c-3.8-1.8-8.3-.3-10.3 3.4l-48.5 91.5c-.8 1.6-1.3 3.3-1.3 5.1 0 5.9 4.8 10.7 10.7 10.7h57.1v25c0 5.2 4.2 9.5 9.4 9.5s9.4-4.3 9.4-9.5v-25h13.3c4.4 0 8-3.6 8-8.1s-3.6-8.1-8-8.1z"
    />
    <circle className="notfound_svg__st1" cx={180} cy={7.5} r={4.5} />
    <ellipse className="notfound_svg__st1" cx={191.5} cy={18} rx={14} ry={18} />
    <path
      className="notfound_svg__st3"
      d="M191.5 30.5c-7 0-12.8-6.7-13.8-15.5-.1.9-.2 1.8-.2 2.8 0 10.1 6.3 18.2 14 18.2s14-8.2 14-18.2c0-.9-.1-1.8-.2-2.8-1 8.8-6.8 15.5-13.8 15.5z"
    />
    <path
      className="notfound_svg__st4"
      d="M220.4 69.5c-.3 1.1-1.6 2-3 2.6-3 1.3-6.7 1.7-10.2 1.8-.9.1-1.8 0-2.7 0-1.8-.2-3.4-.8-4.7-2-.2-.1-.3-.3-.3-.5s.1-.4.3-.5c1.9-1.9 5.1-3.1 8.3-3.9 2.4-.6 6.6-2.3 9.3-2.1 2.5.3 3.4 3.2 3 4.6z"
    />
    <path
      className="notfound_svg__st3"
      d="M220.4 69.5c-.3 1.1-1.6 2-3 2.6-3 1.3-6.7 1.7-10.2 1.8-.9.1-1.8 0-2.7 0-1.8-.2-3.4-.8-4.7-2-.2-.1-.3-.3-.3-.5s.1-.4.3-.5c1.9-1.9 5.1-3.1 8.3-3.9 2.4-.6 6.6-2.3 9.3-2.1 2.5.3 3.4 3.2 3 4.6z"
    />
    <path
      className="notfound_svg__st4"
      d="M182.3 60.4c-.9 1.7-1.1 3.7-.5 5.6.6 1.8 1.8 3.5 3.3 4.6 3.8 3 9 3.4 13.9 3.4 4.3 0 8.5-.3 12.7-.9 1.4-.1 2.8-.4 4.1-.9 1.6-.7 3-1.9 3.5-3.5s.2-3.6-1.2-4.7c-1.2-.9-2.8-1.1-4.3-1.2l-8.7-.5c.9-.9 2-1.3 3-2 1.4-.8 2.3-2.2 3.3-3.5 1.6-1.8 3.2-3.5 5-5.1.3 1.6 1.3 2.9 2.5 4s2.6 2 3.8 3c3 2.5 5.2 6 8.4 8.4 2.2-.6 4.2-1.6 6-3l-1.2-2.2c-.1-.2-.3-.4-.5-.6-.4-.2-.8-.1-1.2-.2-1.1-.2-1.6-1.4-2-2.4-1.2-2.8-3.4-4.9-5.3-7.2-1.4-1.7-2.7-3.5-3.8-5.5-.6-1.2-1.2-2.4-2.1-3.3s-2.1-1.8-3.4-1.8c-1.2 0-2.3.6-3.3 1.2-3.1 1.8-6 3.8-8.9 6-1.5 1.1-3 2.3-4.7 3-.7.3-1.5.5-2.2.9-1.2.6-2.2 1.7-3.2 2.6-3.2 3.4-8.1 5.6-13 5.8z"
    />
    <path
      className="notfound_svg__st5"
      d="M200.5 35.7c0 1.1-.3 2.1-.6 3.1-.4 1-1.2 1.8-2.1 2.1-.7.2-1.4.2-2.1.1-.6-.1-1.2-.3-1.7-.6-.8-.6-1.2-1.6-1.3-2.6-.1-1-.1-2-.2-3-.1-.8-.2-1.6-.4-2.4-.1-.3-.1-.5-.2-.8-.1-.3-.1-.5-.2-.8-.1-.2-.1-.5-.1-.7 0-.2-.1-.4-.1-.6 0-.6-.1-1.2-.3-1.8-.2-.4-.4-.8-.6-1.1-.1-.2-.2-.3-.3-.5-.3-.4-.4-.9-.4-1.4 0-.1 0-.2.1-.2v-.1c.2-.1.4-.2.5-.4 0-.2 0-.5-.1-.7-.1-.3-.2-.6-.4-.9-.1-.2-.4-.5-.4-.7 0-.4.8-.8 1.1-.9 1-.5 2.1-.7 3.2-.7.7 0 1.5.3 1.9.9.3.5.3 1.3.5 1.9 0 .1.1.2.1.3.1.1.2.1.3.1h.6c.3-.1.5-.2.7-.3.1 0 .3-.1.4-.1.6 1.9.7 3.9 1.1 5.9.2 1 .6 1.9.6 2.9v1.5c.1.2.1.4.2.7 0 .1 0 .1.1.2v.2c.1.3.1.8.1 1.4z"
    />
    <circle className="notfound_svg__st5" cx={192.5} cy={17} r={7} />
    <path
      className="notfound_svg__st5"
      d="M236.9 56c-.2-1.9-.9-3.8-2-5.4.2 1.1.4 2.2.5 3.3-.5.1-1 0-1.3-.4-.3-.4-.5-.8-.7-1.2-.8-2-1.8-3.9-3.1-5.6-4.4-.4-8.8-2.2-12.7-4.4-3.9-2.2-7.6-4.8-11.4-7-.7-.4-1.4-.8-2.1-1.1-1.2-.5-2.6-.7-3.7-1.3-.1 0-.2-.1-.2-.1-.3-.1-.5-.3-.7-.5l-.3-.3c-.6-.7-1-1.4-1.3-2.3-.6-1.4-.8-3-1.4-4.4.3-.3.6-.5.9-.7l.1-.1c.2-.1.5-.2.8-.3.1 0 .3-.1.4-.1.7-.1 1.4-.1 2.1.2 1 .3 1.8 1 2.6 1.6 1.3.9 2.6 2 3.8 3.1.7.7 1.3 1.4 2 2 .6.5 1.2.9 1.9 1.3 3.9 2.4 7.8 4.7 11.7 7.1 1.3.8 2.7 1.6 3.9 2.5 2 1.3 3.9 2.7 5.8 4.1 1.1.8 2.2 1.6 3.4 2.5.4.2.7.5 1 .9.2.4.4.8.4 1.3.3 1.6.2 3.5-.4 5.3z"
    />
    <path
      className="notfound_svg__st2"
      d="M202.4 42.2c-.5 2.1-2.6 3.4-3.7 5.3-1.4 2.3-.8 5-.3 7.7-.9.8-1.9 1.7-2.8 2.5-.5.5-1 .9-1.6 1.3-.8.6-1.7 1.1-2.6 1.5-.8.4-1.7.9-2.5 1.3s-1.6.8-2.4 1.1c-1.6.4-3.4.1-5-.4-.2-.1-.4-.1-.5-.2-.1-.1-.2-.1-.3-.2-.2-.3-.2-.6-.2-1 .2-2.8.8-5.5 1.5-8.2.4-1.6.8-3.2 1.4-4.8.6-1.3 1.3-2.6 2-3.9.8-1.6 1.8-3 2.9-4.4.5-.5 1-1.1 1.4-1.8.4-1 .3-2.1.3-3.1.1-.8.3-1.6.6-2.3.2-.4.4-.8.6-1.1.1.3.2.5.3.8.1.2.1.4.2.5.1.2.2.5.3.7.3.6.5 1.3.8 1.9.3.8.7 1.6 1.2 2.3.5.7 1.3 1.1 2.2 1 .8-.1 1.6-.6 2-1.4.4-.7.7-1.5.8-2.3.2-1 .3-2 .1-3-.3-1.9-1.1-3.8-1.7-5.6-.2-.6-.4-1.2-.4-1.8v-.2-.1c.2-.1.5-.2.7-.3.1 0 .3-.1.4-.1l1.5 8.4v.2c.1.4.1.8.2 1.2.1.4.2.7.2 1.1 0 .1.1.2.1.4.1.3.2.7.3 1 1.2 2 2.4 3.9 2 6z"
    />
    <path
      className="notfound_svg__st4"
      d="M230.5 70.6c0 .4.1.8.2 1.2.4.8 1.5 1 2.4 1 5.4.4 10.8.3 16.1-.5.3 0 .6-.1.8-.2.8-.5.3-1.8-.5-2.2s-1.8-.3-2.7-.5c-.9-.2-1.8-.6-2.5-1.1-1.8-1-3.7-2.1-5.5-3.2-1.1-.7-2.3-1.4-3.5-1-3.2 1.1-4.8 3.1-4.8 6.5z"
    />
    <path
      className="notfound_svg__st5"
      d="M191.2 31.1V31.4c-.1.8-.3 1.5-.5 2.3-.6 2.1-1.3 4.1-2 6.1-2 5.4-4 10.8-6 16.1-.5 1.4-1 2.7-1.6 4-.2.4-.4.9-.6 1.3-.7 1.6-1.4 3.3-1.6 5-.2 1.1-.2 2.3-.1 3.4.2 1.1.9 2.2 1.9 2.6.5.2 1.2.5 1.1 1-.1.3-.4.4-.7.5-.8.1-1.6-.1-2.2-.7-.6-.5-1-1.3-1.7-1.4-.5-.1-1 .3-1.5.5-1.9.9-4.2.3-6.2-.4.2-.8 1-1.4 1.8-1.6.8-.2 1.7 0 2.5-.1.3 0 .5-.1.8-.2.2-.2.3-.4.4-.7.5-1.6.9-3.3 1.2-5 .3-1.8.4-3.6.8-5.3.3-1.4.8-2.7 1.1-4 .8-2.8 1.4-5.6 1.9-8.4.2-1.2.4-2.4.5-3.6.1-1 0-2 .1-3 .2-1.3.4-2.6.8-3.8.4-1.4.7-2.7 1.1-4.1.5-2 1.1-4.1 2.4-5.6.9-1.1 2.3-1.8 3.7-1.8.3 0 .5 0 .8.1h.1c.3.1.6.2.8.3.4-.3.6-.2.9.2.2.4.4.9.3 1.4 0 2-.1 3.3-.3 4.6z"
    />
    <path
      className="notfound_svg__st2"
      d="M192.5 32.9l-.1.1-.9 1-.4-2-1.5-6.9c.3 0 .5 0 .8.1 0-.1 0-.2.1-.2l.1.3.5 1.9 1 3.6.2.6.2 1.5z"
    />
    <path
      className="notfound_svg__st1"
      d="M197.5 11.5c0 1.2-.5 2.3-1.4 3.2-.2.2-.3.3-.5.4-.8.6-1.8.9-2.8.9h-5.6c-2.6 0-4.7-2-4.7-4.5s2.1-4.5 4.7-4.5h5.6c2.6 0 4.7 2 4.7 4.5z"
    />
    <circle className="notfound_svg__st1" cx={203} cy={7.5} r={4.5} />
    <path
      className="notfound_svg__st3"
      d="M203.7 3h-.2c2.6.1 4.6 2.1 4.6 4.5s-2.1 4.4-4.6 4.5h.2c2.7 0 4.8-2 4.8-4.5S206.3 3 203.7 3zM180.3 3h.2c-2.6.1-4.7 2.1-4.7 4.5s2.1 4.4 4.7 4.5h-.2c-2.7 0-4.8-2-4.8-4.5s2.2-4.5 4.8-4.5zM196.5 15c-.8.6-1.8 1-2.7 1h-5.5c-1 0-2-.4-2.7-1h10.9z"
    />
    <path
      className="notfound_svg__st5"
      d="M232.5 50.1s1 3.7 2 2.7l-.8-4.8-1.2 2.1z"
    />
    <path
      className="notfound_svg__st6"
      d="M381.8 158.5c3.2 1.2 6.8.2 8.9-2.5s2.4-6.3.6-9.2-5.2-4.4-8.5-3.7l.2 4.1-1.9-3.5c-1.9.9-3.4 2.5-4.1 4.5-.3.7-.4 1.4-.5 2.2-.2 3.6 1.9 6.9 5.3 8.1zM391 135.3c.9-2.6 3.1-4.5 5.8-5.1l.7 3.9 1.2-4.1c3.5.1 6.5 2.3 7.5 5.7s-.2 6.9-3.1 8.9-6.7 1.9-9.4-.3-3.9-5.8-2.7-9zM396.8 176.5c3.8 1.4 8.1-.3 9.9-4 1.8-3.6.6-8.1-2.8-10.3l-.9 3.2-.7-4c-2.7-1-5.7-.4-7.9 1.4s-3.2 4.7-2.7 7.5 2.4 5.2 5.1 6.2zM388.6 189.4c.7 3.7 3.8 6.5 7.6 6.6s7.1-2.4 8-6.1c.9-3.7-.9-7.5-4.3-9.1s-7.5-.7-9.8 2.3l3.6 5.4-4.9-2.8c-.3 1.1-.4 2.4-.2 3.7zM5.8 177.7c2 .7 4.2.1 5.6-1.5s1.5-4 .4-5.8-3.2-2.7-5.3-2.3l.1 2.6-1.2-2.2c-1.2.6-2.1 1.6-2.6 2.8-.2.4-.3.9-.3 1.4-.1 2.2 1.2 4.2 3.3 5zM11.8 162.3c.6-1.6 1.9-2.8 3.6-3.2l.4 2.4.8-2.6c2.2 0 4.1 1.5 4.7 3.5s-.2 4.3-1.9 5.6-4.2 1.2-5.9-.2c-1.8-1.2-2.4-3.5-1.7-5.5zM15.8 189.7c2.4.9 5-.2 6.2-2.5s.4-5-1.8-6.4l-.6 2-.4-2.5c-1.7-.6-3.6-.3-4.9.9s-2 2.9-1.7 4.7 1.5 3.2 3.2 3.8zM9.6 197.9c.4 2.3 2.4 4.1 4.8 4.1s4.5-1.5 5-3.8c.6-2.3-.6-4.7-2.7-5.7s-4.7-.4-6.1 1.5l2.2 3.4-3-1.8c-.3.7-.4 1.5-.2 2.3z"
    />
    <ellipse
      className="notfound_svg__st1"
      cx={184}
      cy={120.5}
      rx={6.5}
      ry={8.5}
    />
    <ellipse
      className="notfound_svg__st1"
      cx={218}
      cy={120.5}
      rx={6.5}
      ry={8.5}
    />
    <path
      className="notfound_svg__st1"
      d="M213.4 145.4c0 3.6-5.4 6.5-12 6.5s-12-2.9-12-6.5c0-3.1 4-5.7 9.4-6.3.9-.1 1.7-.2 2.6-.2 1.4 0 2.9.1 4.3.4 4.5 1 7.7 3.3 7.7 6.1z"
    />
    <path d="M2.5 1.5h405v220H2.5V1.5z" fill="none" />
  </svg>
)

export default SvgNotfound
